import { graphql } from "gatsby";
import React, { FC } from "react";
import AutomationMain from "../components/AutomationUiComponents/AutomationMain";
import Layout from "../components/Layout/Layout";
import { SEO } from "../components/seo";

type Props = {
  data: any;
};

const Automation: FC<Props> = ({ data }) => {
  return (
    <Layout location="/automate">
      <SEO
        title="Automation on AWS Cloud"
        description="Accelerate, secure and modernize software delivery​. Use of automated tools and processes to execute workflows in a cloud environment."
        type="website"
        image="https://www.globaldatanet.com/static/automationMainHeroPic-75e18f01a7b8ad2b0eb366cb158ecd0c.jpg"
      />
      <AutomationMain data={data} />
    </Layout>
  );
};

export const query = graphql`
  query allAutomateBlogs {
    caseStudies: allSanityCaseStudy(
      filter: { tags: { elemMatch: { name: { eq: "Automation" } } } }
      sort: { order: DESC, fields: _createdAt }
      limit: 5
    ) {
      edges {
        node {
          id
          _rawMainImage(resolveReferences: { maxDepth: 20 })
          about {
            _type
            children {
              _key
              _type
              text
            }
          }
          mainImage {
            asset {
              gatsbyImageData(fit: FILLMAX)
            }
          }
          altMainImage
          caseStudyDescription {
            children {
              text
            }
          }
          title
        }
      }
    }
    cloudInsights: allSanityBlog(
      filter: { blogType: { elemMatch: { title: { eq: "cloud-insights" } } } }
      sort: { order: DESC, fields: publishedAt }
      limit: 5
    ) {
      edges {
        node {
          id
          title
          subtitle
          publishedAt
          _rawMainImage(resolveReferences: { maxDepth: 20 })
          mainImage {
            asset {
              gatsbyImageData(fit: FILLMAX)
            }
          }
          alt
        }
      }
    }
  }
`;

export default Automation;
