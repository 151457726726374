import React, { FC } from "react";
import * as styles from "./ThreeColumnBanner.module.scss";
import InnerWrapper from "../../Shared/InnerWrapper/InnerWrapper";
import CircleOutline from "../../Shared/CircleOutlineAndBlur/CircleOutline";
import CircleBlur from "../../Shared/CircleOutlineAndBlur/CircleBlur";

const ThreeColumnBanner: FC<Props> = ({ data }) => {
  return (
    <InnerWrapper>
      <div className={styles.development}>
        <div className={styles.yourBenefits}>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <h2 className="heading">
              <span>.</span>
              {data?.heading}
            </h2>
          </div>
          <div className={styles.yourBenefitsInner}>
            {data?.columns?.map((item: any, index: number) => {
              return (
                <div className={styles.yourBenefitsItem} key={index}>
                  <div className={styles.ourApproachNumber}>
                    <img
                      src={item?.img}
                      alt={item?.alt}
                      style={{
                        width: "100%",
                        height: "300px",
                        objectFit: "cover",
                      }}
                    />
                  </div>
                  <h6 className="heading">
                    <span>.</span>
                    {item?.heading}
                  </h6>
                  <p>{item?.description} </p>
                </div>
              );
            })}
          </div>
          <CircleBlur
            initialScale={0.1}
            initialOpacity={0}
            animateScale={1.8}
            animateOpacity={0.2}
            transitionType={"easeIn"}
            transitionDelay={0.2}
            transitionDuration={1}
            circleStyleClass={styles.heroBackgroundInnerCircleLeft}
          />
          <CircleOutline
            isBlurVisible={true}
            initialScale={0.3}
            animateScale={1}
            transitionType={"easeIn"}
            transitionDelay={0.2}
            transitionDuration={1}
            circleStyleClass={styles.heroBackgroundCircleLeft}
          />
        </div>
      </div>
    </InnerWrapper>
  );
};

export default ThreeColumnBanner;
