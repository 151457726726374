import automationBenefitIcon1 from "../../images/automationBenefitIcon1.png";
import automationBenefitIcon2 from "../../images/automationBenefitIcon2.png";
import automationBenefitIcon3 from "../../images/automationBenefitIcon3.png";
import automationBenefitIcon4 from "../../images/automationBenefitIcon4.png";
import automationBenefitIcon5 from "../../images/automationBenefitIcon5.png";
import automationBenefitIcon6 from "../../images/automationBenefitIcon6.png";
import devOps from "../../images/devOps.jpg";
import devSecOps from "../../images/devSecOps.jpg";
import MLOps from "../../images/MLOps.jpg";
import automationBanner from "../../images/automationBanner.jpg";
import translate from "../../i18n/translate";

export const ourApproachData = [
  {
    count: "01",
    heading: translate("automate-ourApproachHeading1"),
    description: translate("automate-ourApproachDescription1"),
  },
  {
    count: "02",
    heading: translate("automate-ourApproachHeading2"),
    description: translate("automate-ourApproachDescription3"),
  },
  {
    count: "03",
    heading: translate("automate-ourApproachHeading3"),
    description: translate("automate-ourApproachDescription3"),
  },
];

export const BenefitData = {
  heading: translate("optimization-benefits"),
  benefitsDataMobile: [
    {
      count: "01",
      img: automationBenefitIcon1,
      heading: translate("automate-benefitsHeading1"),
      description: translate("automate-benefitsDescription1"),
      alt: "globaldatanet handshake icon",
    },
    {
      count: "02",
      img: automationBenefitIcon2,
      heading: translate("automate-benefitsHeading2"),
      description: translate("automate-benefitsDescription2"),
      alt: "globaldatanet reducing costs icon",
    },
    {
      count: "03",
      img: automationBenefitIcon3,
      heading: translate("automate-benefitsHeading3"),
      description: translate("automate-benefitsDescription3"),
      alt: "globaldatanet innovative ideas icon",
    },
    {
      count: "04",
      img: automationBenefitIcon4,
      heading: translate("automate-benefitsHeading4"),
      description: translate("automate-benefitsDescription4"),
      alt: "globaldatanet increased efficiency icon",
    },
    {
      count: "05",
      img: automationBenefitIcon5,
      heading: translate("automate-benefitsHeading5"),
      description: translate("automate-benefitsDescription5"),
      alt: "globaldatanet reducing failures icon",
    },
    {
      count: "06",
      img: automationBenefitIcon6,
      heading: translate("automate-benefitsHeading6"),
      description: translate("automate-benefitsDescription6"),
      alt: "globaldatanet quality control board icon",
    },
  ],
};

export const ThreeColumnBannerData = {
  heading: translate("automate-service"),
  columns: [
    {
      heading: "DevOps",
      description: translate("automate-serviceDescription1"),
      img: devOps,
      alt: "Backyard playing field in between a house, a pathway and the water",
    },
    {
      heading: "DevSecOps",
      description: translate("automate-serviceDescription2"),
      img: devSecOps,
      alt: "Several wooden boats laying on shore at an orange brown lake",
    },
    {
      heading: "MLOps",
      description: translate("automate-serviceDescription3"),
      img: MLOps,
      alt: "Colorful natural thermal site with visitors",
    },
  ],
};

export const TwoColumnBannerData = {
  heading: translate("automate-bannerHeading"),
  details: translate("automate-bannerDescription"),
  img: automationBanner,
  mobileImg: automationBanner,
  alt: "Wooden pathway leading from the beach into the ocean to showcase smooth transition to automation",
};

export const ContactFormData = {
  heading: "Bring your application to a new level ",
};
