// extracted by mini-css-extract-plugin
export var aboutMainCircleBlurBlue = "CaseStudy-module--aboutMainCircleBlurBlue--omJRB";
export var aboutMainCircleBlurGreen = "CaseStudy-module--aboutMainCircleBlurGreen--k-KOK";
export var approach = "CaseStudy-module--approach--yZKAP";
export var automate = "CaseStudy-module--automate--CNJXa";
export var automatebenifit = "CaseStudy-module--automatebenifit--WsmZZ";
export var backgroundCircles = "CaseStudy-module--backgroundCircles--hHmKp";
export var backgroundCirclesBlogArticles = "CaseStudy-module--backgroundCirclesBlogArticles--GCiWR";
export var benefit = "CaseStudy-module--benefit--kq687";
export var blogModalNav = "CaseStudy-module--blogModalNav--FNOeW";
export var blogModalWrapper = "CaseStudy-module--blogModalWrapper--a6vJl";
export var blogTag = "CaseStudy-module--blogTag--MR4oA";
export var blogTagsWrapper = "CaseStudy-module--blogTagsWrapper--BFZ81";
export var blogsCircleBlueRight = "CaseStudy-module--blogsCircleBlueRight--QfPQw";
export var casePreviewImage = "CaseStudy-module--casePreviewImage--eA-IX";
export var caseStudiesColumnLeft = "CaseStudy-module--caseStudiesColumnLeft--4IM9H";
export var caseStudiesColumnRight = "CaseStudy-module--caseStudiesColumnRight--AbCPt";
export var caseStudiesContainerWrapper = "CaseStudy-module--caseStudiesContainerWrapper--GlcIx";
export var caseStudiesMainArticles = "CaseStudy-module--caseStudiesMainArticles--tvyHv";
export var caseStudiesMainContainer = "CaseStudy-module--caseStudiesMainContainer--q0fZt";
export var caseStudiesMainContainerHead = "CaseStudy-module--caseStudiesMainContainerHead--iMd2g";
export var caseStudiesMainTags = "CaseStudy-module--caseStudiesMainTags--Zve21";
export var caseStudiesRow = "CaseStudy-module--caseStudiesRow--VAVn-";
export var caseStudiesTag = "CaseStudy-module--caseStudiesTag--DR9HJ";
export var casestudy = "CaseStudy-module--casestudy--uYc3M";
export var cloudinside = "CaseStudy-module--cloudinside--96csZ";
export var contact = "CaseStudy-module--contact--Db5iX";
export var contactButton = "CaseStudy-module--contactButton--OLzqe";
export var contactCheckBox = "CaseStudy-module--contactCheckBox--6BC7p";
export var contactConfirmLabel = "CaseStudy-module--contactConfirmLabel--yRs0Q";
export var contactCountry = "CaseStudy-module--contactCountry--b+ej6";
export var contactEmail = "CaseStudy-module--contactEmail--G6uWx";
export var contactFormAddress = "CaseStudy-module--contactFormAddress--XuNdN";
export var contactFormAddressDiv = "CaseStudy-module--contactFormAddressDiv--HHv0z";
export var contactFormAddressItem = "CaseStudy-module--contactFormAddressItem--W6PaO";
export var contactFormAndAddressContainer = "CaseStudy-module--contactFormAndAddressContainer--wBSVI";
export var contactFormContainer = "CaseStudy-module--contactFormContainer--NBhx9";
export var contactFormInputs = "CaseStudy-module--contactFormInputs--v4Oom";
export var contactFormWrapper = "CaseStudy-module--contactFormWrapper--uUnxC";
export var contactOutlineCircle = "CaseStudy-module--contactOutlineCircle--YigI1";
export var contactPhoneAndEmail = "CaseStudy-module--contactPhoneAndEmail--xFLEM";
export var contactTabsCountry = "CaseStudy-module--contactTabsCountry--1F3Eq";
export var contactUs = "CaseStudy-module--contactUs--ovK3t";
export var contactUsForm = "CaseStudy-module--contactUsForm--xSnfh";
export var contactUsHeader = "CaseStudy-module--contactUsHeader--GmeJY";
export var contacted = "CaseStudy-module--contacted--E759D";
export var contacts = "CaseStudy-module--contacts--T-uza";
export var developments = "CaseStudy-module--developments--vwy+9";
export var fadeInBottomLeft = "CaseStudy-module--fadeInBottomLeft--r8v7I";
export var fadeInBottomRight = "CaseStudy-module--fadeInBottomRight--1pqxA";
export var filterActionButton = "CaseStudy-module--filterActionButton--wZ4yH";
export var head = "CaseStudy-module--head--s9Y7e";
export var heroBackgroundInnerCircleLeft = "CaseStudy-module--heroBackgroundInnerCircleLeft--YHfhd";
export var inside = "CaseStudy-module--inside--Twu85";
export var knowUsImageAutomate = "CaseStudy-module--knowUsImageAutomate--UTZBD";
export var knowUsTextAutomate = "CaseStudy-module--knowUsTextAutomate--mEagQ";
export var threecolumn = "CaseStudy-module--threecolumn--QgDim";
export var topBanner = "CaseStudy-module--topBanner--FkDhj";
export var twocolumn = "CaseStudy-module--twocolumn--Sy1l9";